[dir=ltr] .tabs-scripts .nav-link {
  padding-left: 10px !important;
  padding-right: 10px !important;
}[dir=rtl] .tabs-scripts .nav-link {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.tabs-school .nav-item a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
[dir] .tabs-school .nav-item a {
  padding-bottom: 10px;
  padding-top: 10px;
}
[dir=ltr] .tabs-school .nav-item a {
  padding-left: 0px;
  padding-right: 0px;
}
[dir=rtl] .tabs-school .nav-item a {
  padding-right: 0px;
  padding-left: 0px;
}
.tabs-school > .col-auto {
  min-width: 160px;
}
.tabs-school .card-scripts textarea {
  min-height: 200px;
  resize: vertical;
}